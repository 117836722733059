import Api from '../../backend/api';
//import headers from '../../backend/headers';
//const headers = AuthHeaders();

export default {
     upload(data){
          console.log("uploading ",data);
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          return Api.post('attachment',data,{headers:auth});
     },
     get(data){
          console.log("Fetching file:",data);
          var headers = {
               responseType: 'blob',
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          console.log("header",headers);
        //  return Api.post('attachment/get',{filename:data.filename},{headers:header});
          return Api.post('attachment/get',{filename:data.filename},{
               responseType: 'blob',
               headers:{authorization: "Bearer " + localStorage.getItem('accesstoken')}
          });

     }    
}


