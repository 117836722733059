<template>
    <div class="bg">
        <div class="login-wrapper">
            <v-row>
                <v-col>
                    <v-container class="in-wrapper">
                        <h2 class="head-title">
                            LIFE MIB
                            <span style='display:block !important;font-size:13px;'>V 1.0.2</span>
                        </h2>
    
                        <h3 class="head-subtitle">Don't have an account yet? <a href="#" class="ac-reg">Register</a></h3>
                        <v-container v-if="!askForPhoneNumber && !askForSmsOTP" class="mt-5">
                            <span style='color:#ff0000' v-if="err!=''">{{err}}<br/></span>
                            <v-text-field hide-details class="ct" dense placeholder="Your Email" v-model="user.email" outlined>
                                <template v-slot:prepend-inner>
                                    <v-icon class="vv" medium>mdi-email</v-icon>
                                </template>
                            </v-text-field>
                            <v-text-field hide-details class="ct"  dense placeholder="Your Password" v-model="user.password" type="password" outlined>
                                <template v-slot:prepend-inner>
                                    <v-icon class="vv" medium>mdi-key</v-icon>
                                </template>
                            </v-text-field>
                            <v-btn block depressed large class="btn-login" @click="login()">
                                <v-icon>mdi-lock</v-icon>
                                <v-spacer></v-spacer>
                                login
                                <v-spacer></v-spacer>
    
                            </v-btn>
                        </v-container>
                        <v-container v-if="askForPhoneNumber" class="mt-5">
                            <span style='color:#ff0000' v-if="err!=''">{{err}}<br/></span>
                              <vue-tel-input v-bind="vueTelInputOptions" class="ct" v-model="user.phoneNumber"></vue-tel-input>
                            <v-btn block depressed large class="btn-login" @click="login()">
                                <v-icon>mdi-floppy</v-icon>
                                <v-spacer></v-spacer>
                                Save Phone Number
                                <v-spacer></v-spacer>
                            </v-btn>
                        </v-container>
    
                        <v-container v-if="askForSmsOTP" class="mt-5">
                            <span style='color:#ff0000' v-if="err!=''">{{err}}<br/></span>
                            <v-text-field hide-details class="ct"  dense placeholder="Your OTP" v-model="user.smsOTP" type="password" outlined>
                                <template v-slot:prepend-inner>
                                    <v-icon class="vv" medium>mdi-lock</v-icon>
                                </template>
                            </v-text-field>
                            <v-btn block depressed large class="btn-login" @click="login()">
                                <v-icon>mdi-check</v-icon>
                                <v-spacer></v-spacer>
                                Verify
                                <v-spacer></v-spacer>
                            </v-btn>
                        </v-container>
                    </v-container>
                </v-col>
            </v-row>
        </div>
    </div>
    </template>
    
    <script>
    import loginAPI from '../backend/login';
    import router from '../router';
    import vti from 'vue-tel-input'
    console.log("ð ~ file: Login.vue ~ line 54 ~ defaultOptions", vti)
    export default {
        name: 'LoginApp',
        data: () => ({
            user: {
                email: '',
                password: '',
                phoneNumber: '',
                smsOTP: '',
            },
            err:'',
            askForPhoneNumber: false,
            askForSmsOTP: false,
            vueTelInputOptions: {
              mode: 'international',
            }
        }),
        methods:{
            login(){
                loginAPI.login(this.user).then((x)=>{
                    console.log("login result",x);
                    if(x.data.success){
                        loginAPI.storeAuthData(x).then(()=>{
                            router.push({path:'dashboard'});
                        })
                    } else if(x.data.requirePhoneNumber) {
                      this.askForPhoneNumber = true;
                      this.err = x.data.msg;
                    } else if (x.data.requireSmsOTP){
                      this.askForPhoneNumber = false;
                      this.askForSmsOTP = true;
                      this.err = x.data.msg;
                    } else {
                      this.err = x.data.msg;
                    }
    
                }).catch((x)=>{
                        console.log(x);
                        this.err = x.data.msg;
                })
            },
        }
    }
    </script>
    
    <style scoped>
    .bg {
        background: url('../assets/bg2.jpg') 90% 90% no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    
    .login-wrapper {
        width: 630px;
        background1: rgba(255, 255, 255, 1);
        background: rgb(218, 238, 250, 0.9);
        background: #12233f;
    
        height: 100vh;
        float: right;
    }
    
    .in-wrapper {
        margin-top: 80px;
        text-align: center;
        width: 400px;
    }
    
    .head-title {
        color: #37517e;
        color:#0297ee;
        font-size: 40px;
    }
    
    .head-subtitle {
        color: #cecccc;
        font-size: 16px;
        font-weight: 300;
        margin-top: 50px;
    }
    
    .head-subtitle2 {
        color: #cecccc;
        font-size: 16px;
        font-weight: 300;
        margin-top: 10px;
    }
    
    .head-subtitle a,
    .head-subtitle2 a {
        font-weight: 400 !important;
        color: #0297ee;
        text-decoration: none !important;
    }
    
    .btn-login {
        background: #37517e !important;
        color: #ffffff;
    }
    .vv{color:#cecccc}
    .ct{margin-top:10px;margin-bottom:10px;background-color:#e4eeff !important}
    
    </style>
    
    