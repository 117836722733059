import Api from '../../backend/api';
//import headers from '../../backend/headers';
import {AuthHeaders} from '../../backend/auth';

//const headers2 = {};
//import headers from '../../backend/auth'
export default {
     get(user){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }         
          return Api.get('forms/my/'+JSON.stringify(user),{headers:auth})
     },
     getTasks(user){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }          
          return Api.get('forms/my/tasks/'+JSON.stringify(user),{headers:auth})
     },
     getOtherUserInbox(user){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }          
          return Api.get('forms/other/inbox/'+JSON.stringify(user),{headers:auth})
     },
     searchForms(user){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }         
          return Api.get('forms/my/search/'+JSON.stringify(user),{headers:auth})
     },
     generateReport(creteria){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }         
          return Api.get('forms/my/generatereport/'+JSON.stringify(creteria),{headers:auth})
     },
     create(data){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }         
          return Api.post('forms',data,{headers:auth})
     },
     getSingle(id){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }         
          return Api.get('forms/'+id,{headers:auth})
     },
     update(data){
          console.log("update:",data);
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }         
          return Api.put('forms/'+data._id,data,{headers:auth})
     }    
}


