<template>
  <div class="inner-container">
    <div class="widget">
      <v-snackbar v-model="sbar.show" :color="sbar.stl" :timeout="sbar.timeout" absolute centered elevation="24" top>
        {{ sbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="sbar.show=false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-row>
        <v-col>
          <h3>Agent List</h3><br/>
          <v-text-field v-model="search" append-icon="mdi-magnify" class="search-box" dense hide-details label="Search"
                        outlined single-line></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn class="iv-btn" @click="entryMode = 0;isVisible = true;itemEntry={...itemBlankEntry}">
            <v-icon class="btn-icon">mdi-plus-circle</v-icon>
          </v-btn>
          <br/><br/>

          <v-btn-toggle v-model="btngroup" borderless class="float-right" color="#0c2556" dense>
            <v-btn @click="loadForm(1)">
              <v-icon left>
                mdi-lightbulb-on
              </v-icon>
              <span class="hidden-sm-and-down"> Active</span>
            </v-btn>

            <v-btn @click="loadForm(0)">
              <v-icon left>
                mdi-lightbulb-off
              </v-icon>
              <span class="hidden-sm-and-down">inactive</span>

            </v-btn>
          </v-btn-toggle>
        </v-col>

      </v-row>

      <v-data-table :headers="headers" :items="items" :search="search" class="list">
        <template v-slot:item.email="{ item }">
          <span class="list-title">{{ item.email }}</span>

        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="item.status" style="color: #009900; font-weight: 600">ACTIVE</span>
          <span v-if="!item.status" style="color: #999999; font-weight: 600">INACTIVE</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="blue" style="font-size: 20px" title='Edit' @click="
              itemEntry = item;
              entryMode = 1;
              isVisible = true;
            ">
            mdi-square-edit-outline
          </v-icon>
          <v-icon color="green" style="font-size: 20px; margin-left: 10px" title='Restriction Rules' @click="
              itemEntry = item;
              entryMode = 1;
             tempRules = [...itemEntry.rules];

              isRuleVisible = true;
            ">
            mdi-cube-scan
          </v-icon>

          <v-icon color="silver" style="font-size: 20px; margin-left: 10px" title='Clone this product' @click="
              itemEntry = item;
              entryMode = 0;
              isVisible = true;
            ">
            mdi-content-duplicate
          </v-icon>

        </template>
      </v-data-table>
    </div>

    <v-row justify="center">
      <v-dialog v-model="isVisible" max-width="500" overlay-opacity="0.8" persistent>
        <v-form v-model="isValidForm">
          <v-card>
            <v-card-title>
              <span class="headline">{{ FormTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-select v-model="itemEntry.account" :items="lmAllAgents" dense item-text="email" item-value="_id"
                              label="Account" return-object>
                    </v-select>
                  </v-col>

                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="itemEntry.email" :rules="[rules.required]" dense
                                  label="Email"></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="itemEntry.password" :rules="[rules.required]" dense
                                  label="Password"></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="itemEntry.fname" :rules="[rules.required]" dense
                                  label="Fist Name"></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-text-field v-model="itemEntry.lname" :rules="[rules.required]" dense
                                  label="Last Name"></v-text-field>
                  </v-col>

                  <v-col cols="12" lg="12" md="12" sm="12">
                    <v-checkbox v-model="itemEntry.status" label="Status"></v-checkbox>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" small @click="isVisible = false">Cancel</v-btn>
              <v-btn v-if="entryMode == 0" :disabled="!isValidForm" color="success" small @click="
                  isVisible = false;
                  saveForm();
                ">Save
              </v-btn>
              <v-btn v-if="entryMode == 1" :disabled="!isValidForm" color="success" small @click="
                  isVisible = false;
                  updateForm();
                ">Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-form v-model="isRuleValidForm">

        <v-dialog v-model="isRuleVisible" max-width="800" overlay-opacity="0.8" persistent>
          <v-card>
            <v-card-title>
              <v-row no-gutters>
                <v-col>
                  <span class="headline ">Manage Rules</span>
                </v-col>
                <v-col align="right" class="mr-2">
                  <v-icon color="blue" large @click="tempRules.push({title:'',ruleFormula:''})">mdi-plus</v-icon>
                </v-col>
              </v-row>

            </v-card-title>

            <v-card-text>
              <v-container>
                <v-data-table :headers="rule_headers" :items="tempRules" class="list-rate" dense>

                  <template slot="item" scope="props">
                    <tr>
                      <td>
                        <v-select v-model="props.item.title" :items="rule_types" dense item-text="title"
                                  item-value="val" label="Rule Type" @change="getRelated(props.item.title)">
                        </v-select>
                      </td>
                      <td>

                        <v-autocomplete
                          v-if="props.item.title === 'appointments' || props.item.title === 'leads' || props.item.title === 'deals' || props.item.title === 'agentDrive'"
                          v-model="props.item.ruleFormula"
                          :items="product_types.filter(itm => itm.validFor.includes(props.item.title))" chips color="blue-grey lighten-2"
                          item-text="title" item-value="_id" label="Select" multiple outlined>
                          <template v-slot:selection="data">
                            <v-chip :input-value="data.selected" close v-bind="data.attrs" @click="data.select"
                                    @click:close="remove(data.item)">
                              {{ data.item.title }}
                            </v-chip>
                          </template>
                        </v-autocomplete>

                      </td>
                      <td>
                        <v-icon color="red" style="font-size: 20px; margin-left: 10px" title="Delete"
                                @click="tempRules.splice(props.index,1)">mdi-trash-can-outline
                        </v-icon>
                      </td>
                    </tr>
                  </template>

                </v-data-table>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error1" small text @click="isRuleVisible = false;tempRules=[]">Cancel</v-btn>
              <v-btn v-if="entryMode == 0" :disabled="!isRuleValidForm" color="success" small
                     text @click="itemEntry.rules=tempRules;isRuleVisible = false;saveForm();">
                Save
              </v-btn>
              <v-btn v-if="entryMode == 1" :disabled="!isRuleValidForm" color="green" small
                     text
                     @click="itemEntry.rules=tempRules;isRuleVisible = false;updateForm();">Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </v-row>

  </div>
</template>

<script>
import genericAPI from "../backend/generic";

export default {
  data() {
    return {
      btngroup: 0,
      search: '',
      apiPath: 'entity/agents',
      currentTab: 1,
      headers: [{
        text: "Email",
        value: "email",
        align: "start",
      },

        {
          text: "First Name",
          value: "fname",
          align: "start",
        },
        {
          text: "Last Name",
          value: "lname",
          align: "start",
        },
        {
          text: "Account",
          value: "account.email",
          align: "start",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "",
          value: "actions",
          align: "end",
        },
      ],
      items: [],
      isVisible: false,
      isValidForm: false,
      itemEntry: {
        account: {},
        email: "",
        fname: "",
        lname: "",
        logininfo: null,
        rules: [],
        status: 1,
      },
      itemBlankEntry: {
        account: {},
        email: "",
        fname: "",
        lname: "",
        logininfo: null,
        rules: [],
        status: 1,
      },
      entryMode: 0,
      rules: {
        required: (value) => !!value || "Required.",
      },
      formRules: {
        required: (value) => !!value || "Required.",
      },
      isRuleVisible: false,
      isRuleValidForm: false,
      accounts: [],
      provider_items: [],
      product_types: [
        {
          title: "Add",
          _id: "Add",
          validFor: ["appointments", "leads", "deals"]
        },
        {
          title: "Convert",
          _id: "Convert",
          validFor: ["appointments", "leads", "deals"]
        },
        {
          title: "Delete",
          _id: "Delete",
          validFor: ["appointments", "leads", "deals"]
        },
        {
          title: "Bot",
          _id: "Bot",
          validFor: ["appointments", "leads", "deals"]
        },
        {
          title: "Send Text",
          _id: "Send Text",
          validFor: ["appointments", "leads", "deals"]
        },
        {
          title: "LM Chat",
          _id: "LM Chat",
          validFor: ["agentDrive"]
        },
        {
          title: "UW Scanner",
          _id: "UW Scanner",
          validFor: ["agentDrive"]
        },
        {
          title: "Smart Proposal",
          _id: "Smart Proposal",
          validFor: ["agentDrive"]
        },
      ],
      tempRules: [],
      rule_headers: [{
        text: "Rule Title",
        value: "title",
        width: 200
      }, {
        text: "Rule Actions",
        value: "ruleFormula",
      },
        {
          text: "",
          value: "action",
          width: 50
        }
      ],
      rule_types: [
        {
          title: 'Appointments',
          val: 'appointments'
        },
        {
          title: 'Leads',
          val: 'leads'
        },
        {
          title: 'Deals',
          val: 'deals'
        },
        {
          title: 'Agent Drive',
          val: 'agentDrive'
        }
      ],

      sbar: {
        show: false,
        timeout: 1500,
        message: '',
        stl: 'success'
      },

      adminAccount: {},
      lmAllAgents: [],

    };
  },
  computed: {
    FormTitle() {
      return this.entryMode === 0 ? "New Agent" : "Edit Agent";
    },
  },
  methods: {
    saveForm() {
      genericAPI.save(this.apiPath, this.itemEntry).then((x) => {
        console.log("x", x);
        this.isVisible = false;
        this.showSnakbar(true, 'Updated successfully.')
        this.loadForm(1);

      }).catch((e) => {
        this.showSnakbar(false, e)
        console.log("save():", e);
      });
    },
    updateForm() {
      genericAPI.update(this.apiPath, this.itemEntry).then((x) => {
        console.log("x", x);
        this.isVisible = false;
        this.showSnakbar(true, 'Updated successfully.')
        this.loadForm(1);

      }).catch((e) => {
        this.showSnakbar(false, e)
        console.log("update():", e);
      });
    },
    getRelated(x) {
      // console.log("x", x);
      // if (x == 'providers') {
      //     console.log("x2", x);

      //     genericAPI.getAll('entity/providers/status', {
      //         status: 1
      //     }).then((x) => {
      //         this.provider_items = x.data.msg;
      //         console.log("dynamic items", this.provider_items);
      //     }).catch((e) => {
      //         console.log("e", e);
      //     });
      // }
      // if (x == 'product_types') {

      // }
    },
    remove(item) {
      const index = this.friends.indexOf(item.name)
      if (index >= 0) this.friends.splice(index, 1)
    },

    loadForm(status) {
      const criteria = {
        status: status
      }
      genericAPI.getAll(this.apiPath + '/status', criteria).then((x) => {
        this.items = x.data.msg;
        console.log("items", this.items);
      }).catch((e) => {
        console.log("getAll():", e);
        this.showSnakbar(false, e)
      });
    },
    showSnakbar(success, m) {
      var msg = m;

      if (success == false && m.message.indexOf('status code 401') > 0) {
        msg = '401 - Unauthorized / Expired session / Invalid Token. Please try re-login.'
        success = false
      }

      this.sbar = {
        show: true,
        timeout: success == true ? 2000 : -1,
        message: msg,
        stl: success == true ? 'success' : 'red'
      }
    }

  },

  mounted() {
    genericAPI.getAll('entity/accounts/status', {
      status: 1
    }).then((x) => {
      this.accounts = x.data.msg;
      console.log("items", this.items);
    });
    genericAPI.adminLogin().then((x) => {
      this.adminAccount = x.data;
      console.log("this.adminAccount", this.adminAccount);

      genericAPI.lmAPI.lmAllAgents(x.data.token).then((x) => {
        this.lmAllAgents = x.data;
        debugger;
        // console.log("this.adminAccount", this.adminAccount);
        // // lmAllAgents
      });

    });
    genericAPI.getAll('entity/providers/status', {
      status: 1
    }).then((x) => {
      // this.provider_items = x.data.msg;
      // console.log("dynamic items", this.provider_items);
    }).catch((e) => {
      console.log("e", e);
    });

    this.loadForm(1);
  },
};
</script>
