import Api from './api';
import moment from 'moment';

export default {
     login(data){
          return Api.post('auth/login',data,{headers:{}})
     },
     storeAuthData(x){
          return new Promise (async(resolve, reject)=>{
               if(x.data.success){
                    console.log("Authenticated:",x.data);
                    
                    //set session expirty for frontend
                    var expiry = moment(new Date(),'MM/DD/YYYY HH:mm:ss').add(12, 'hours');
                    expiry = moment(expiry.toDate()).format('MM/DD/YYYY HH:mm:ss');
                    expiry = new Date();
                    expiry = expiry.setDate(expiry.getDate()+7)

                    await localStorage.removeItem('user');
                    await localStorage.removeItem('accesstoken');
                    await localStorage.removeItem('session_expiry');

                    await localStorage.setItem('user',JSON.stringify(x.data.msg));
                    await localStorage.setItem('accesstoken',x.data.token);
                    await localStorage.setItem('session_expiry',expiry)
     
                    /*
                    await Login.getOnBehalf(response.data.user.mail).then(async (r)=>{
                         await localStorage.setItem('onbehalf',JSON.stringify(r.data.items));
                         return commit('SET_ON_BEHALF', r.data.items);
                    })*/
                    resolve(x.data);
               }
               else
                    reject(x.data)
          })
     }
}