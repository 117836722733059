import Form from './Form';

const getDefaultState = () =>{
     return {
          form:{
               itemtype:'',
               title: '',
               content: {},
               workflow:[],
               forward:[],
               comments:[],
               attachments:[],
               formtasks:[],
               permissions:[],
               status:0,
               alert:0,
               revision:1,   
               createdby:'',
               updatedby:''               
          },
          singleForm:{
               itemtype:'',
               title: '',
               content: {},
               workflow:[],
               forward:[],
               comments:[],
               attachments:[],
               formtasks:[],
               permissions:[],
               status:0,
               alert:0,
               revision:1,   
               createdby:'',
               updatedby:''               
          },
          forms:[]
     }
}
const state = getDefaultState();
const actions = {
     MY_FORMS({commit}, data){
          return new Promise ((resolve, reject) => {
              return Form.get(data).then(response =>{
                    if(response.data.success){
                         commit('SET_FORMS', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     MY_TASKS({commit}, data){
          return new Promise ((resolve, reject) => {
              return Form.getTasks(data).then(response =>{
                    if(response.data.success){
                         commit('SET_FORMS', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     MY_REPORTS({commit}, data){
          return new Promise ((resolve, reject) => {
              return Form.generateReport(data).then(response =>{
                    if(response.data.success){
                         commit('SET_FORMS', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
    
     OTHER_INBOX({commit}, data){
          return new Promise ((resolve, reject) => {
              return Form.getOtherUserInbox(data).then(response =>{
                    if(response.data.success){
                         commit('SET_FORMS', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     SEARCH_FORMS({commit}, data){
          return new Promise ((resolve, reject) => {
              return Form.searchForms(data).then(response =>{
                    if(response.data.success){
                         commit('SET_FORMS', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     GET_SINGLE_FORM({commit}, id){
          return new Promise ((resolve, reject) => {
           Form.getSingle(id).then(async(response) =>{

                    if(response.data.success){
                         if(response.data.item.status===200){
                              var currentUser = JSON.parse(localStorage.getItem('user'));
                              var onbehalf = localStorage.getItem('onbehalf') === null ? null : JSON.parse(localStorage.getItem('onbehalf'));
                              console.log("onbehalf in single",onbehalf);
                              if(onbehalf!=null)
                              {
                                        await onbehalf.map(async(r)=>{
                                             await response.data.item.workflow.map((x, index)=>{
                                                  if(x.status===200 && x.mail===r.user_from.mail){
                                                       currentUser.issubstitute = 1;
                                                       currentUser.status = 200;
                                                       var p = response.data.item.workflow[index];
                                                       currentUser.substitute = p;
                                                       response.data.item.workflow[index] =currentUser;
                                                  }
                                             });
                                             await response.data.item.forward.map((x, index)=>{
                                                  if(x.status===200 && x.mail===r.user_from.mail){
                                                       currentUser.issubstitute = 1;
                                                       currentUser.status = 200;
                                                       var p = response.data.item.forward[index];
                                                       currentUser.substitute = p;
                                                       currentUser.createdby = p.createdby;
                                                       currentUser.createdAt = p.createdAt;
                                                       currentUser.updated_at = p.updated_at;
                                                       currentUser.sentby = p.sentby;
                                                       response.data.item.forward[index] =currentUser;
                                                  }
                                        });
                                        }) 
                              }
                              
                         }
                         commit('SET_SINGLE_FORM', response.data.item)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     CREATE_FORM({commit},data){
          return new Promise ((resolve, reject) => {
              return Form.create(data)
                         .then((response) =>{commit('CLEAR_FORM',''); resolve(response)})                 
                         .catch(e => reject(e))
                    })               
     },
     UPDATE_FORM({commit}, data) {
          console.log("UPDATE_FORM:",data);
          return new Promise ((resolve, reject) => {
              return Form.update(data)
                         .then((response) =>{resolve(response);}).
                         catch((e)=> reject(e))
               })                    
     },
     CLEAR_FORM_STATE({commit},data){
          commit('CLEAR_FORM',data);
     }
}

const mutations = {
       SET_FORMS(state, data){
          state.forms = data;
        },
        SET_SINGLE_FORM(state, data){
          state.singleForm = data;
        },
        CLEAR_FORM(state,data){
             Object.assign(state,getDefaultState());                         
        }
}

export default {
     state,
     getters:{},
     actions,
     mutations
}
