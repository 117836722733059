import Vue from 'vue'
import Vuex from 'vuex'
import login from '../modules/login'
import form from '../modules/form'
import attachment from '../modules/attachment'
import subs from '../modules/subs'

//import mytasks from '../modules/mytasks'
//import minutesheet from '../modules/minutesheet'
//import attachment from '../modules/attachments'

Vue.use(Vuex)

export default new Vuex.Store({
     modules: {
          login,
          form,
          attachment,
          subs
     }
})