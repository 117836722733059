import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store/store'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import moment from 'moment';
import VueMask from "v-mask";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(CKEditor);
Vue.use(VueTelInput);

Vue.config.productionTip = false
Vue.use(VueMask);

window.currentUser = ()=>{
  var user = JSON.parse(localStorage.getItem('user'));
  var expiry = localStorage.getItem('session_expiry')
  var currentDate = moment(new Date(),'MM/DD/YYYY HH:mm:ss');
  currentDate = moment(currentDate.toDate()).format('MM/DD/YYYY HH:mm:ss');

  if(expiry>currentDate)
   return user
  else
  return null
}

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss')
  }
});


router.beforeEach(async(to, from, next) => {
  var user = localStorage.getItem('user');
  var expiry = localStorage.getItem('session_expiry')
  var currentDate = moment(new Date(),'MM/DD/YYYY HH:mm:ss');
  currentDate = moment(currentDate.toDate()).format('MM/DD/YYYY HH:mm:ss');


  console.log("new user:",JSON.parse(user));
  console.log("current date:",new Date().getTime());
  console.log("session expiry:",expiry);

//  user = await store.state.login.user;
  if (to.name!='login' && (expiry==undefined || expiry < new Date().getTime()))
  {
    console.log("Trying to access protected link...redirecting to Login:",to.name,from.name);
    next({ name: 'login' });
  }
  else {
    console.log("EEELLLSSEE")
    //if(to.name === 'login' && (expiry==undefined || expiry < currentDate))
    //   console.log("Redirecting to Login:",user,to.name,from.name);
    //else
    //console.log("Valid user...protected link granted.",user,to.name,from.name);
    //console.log("Protected:",user,to.name,from.name);
    next();
  }


})


new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
