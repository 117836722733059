<template>
<div class="app-wrapper">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app class="nav-drawer drawer border" floating>
        <v-list nav id="SidebarNavigation">
            <v-list-item-group v-model="sideMenu">
                <v-list-item v-for="(item, i) in sideMenuItems" :key="i" @click="goto(item)">

                    <v-list-item-icon>
                        <v-icon v-text="item.icon" class="menu-icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>

                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app flat dense class="app-bar">
        <div class="brand">
            <v-toolbar-title style="width1: 187px;padding:9px;text-align:center !important;;margin:0 auto;margin-left:55px !important" class="pl-0 top-title mx-auto">
                <span class="hidden-sm-and-down">LIFE MIB</span>
            </v-toolbar-title>
        </div>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-icon" />

        <div class="search-control" v-if="1==2">
            <input type="text" />
        </div>
        <v-spacer />

        <v-btn icon>
            <v-icon class="icon-color-top">mdi-apps</v-icon>
        </v-btn>

        <v-btn icon>
            <v-icon class="icon-color-top" dark>mdi-bell</v-icon>
        </v-btn>

        <v-btn dark text>
            <v-menu bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn  color="dark blue" dark v-on="on">
                        <v-avatar size="32px" item>
                            <v-img :src="user.thumbnailPhoto || getAvatar()" alt="Vuetify" />
                        </v-avatar>
                        <span class="text-right" style="white-space:pre !important;word-wrap:initial !important;float:right !important" dark v-if="user!=null || user!=undefined">
                            {{ user.fname + ' ' + user.lname  }}
                        </span>
                    </v-btn>
                </template>
                <v-list class="mt-10">
                    <v-list-item @click="logout()">
                        <v-list-item-title>Log out</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-btn>

    </v-app-bar>

    <router-view />

</div>
</template>

<script>
export default {
    name: 'App',
    data: () => ({

        drawer: true,
        sideMenu: 0,
        sideMenuItems: [

            {
                title: "Dashboard",
                icon: "mdi-home-analytics",
                routeName: "dashboard"
            },
            {
                title: "Providers",
                icon: "mdi-domain",
                routeName: "providers"
            },
            {
                title: "Products",
                icon: "mdi-cart-variant",
                routeName: "products"
            },
            {
                title: "Underwriting",
                icon: "mdi-alpha-u-circle-outline",
                routeName: "uw"
            },
            {
                title: "Plans",
                icon: "mdi-bulletin-board",
                routeName: "plans"
            },

            {
                title: "Accounts",
                icon: "mdi-account-group-outline",
                routeName: "accounts"
            },
            {
                title: "Agents",
                icon: "mdi-face-agent",
                routeName: "agents"
            },
            {
                title: "Test API",
                icon: "mdi-database-clock-outline",
                routeName: "testapi"
            }

        ]
        //
    }),
    computed: {
        user() {
            return window.currentUser()
        }
    },
    methods: {
        getAvatar(mail) {
            try {
                // var avatar = require('../assets/avatar/' + mail.toLowerCase() + '.jpg');
                //   console.log("mail", mail);
                return require('../assets/avatar.png');
                //return avatar
                // do something
            } catch (e) {
                // do something else
                return require('../assets/avatar.png');
            }

        },
        goto(route) {
            console.log(route.routeName);
            this.$router.push({
                name: route.routeName
            })
        },
        logout() {
            localStorage.removeItem('user');
            localStorage.removeItem('accesstoken');
            localStorage.removeItem('session_expiry');
            this.$router.push({
                path: 'login'
            });
        }
    }

};
</script>

<style scoped>
.app-wrapper {}

.nav-drawer {
    background: #2b383e !important;
    width: 240px !important;
    border-right1: 1px solid #e9e9e9 !important;
    box-shadow1: 0 0 !important;
    background: #f1f5fe !important;
    background1: #f1f1ff !important;

    background1: #605bff !important;
    border-right1: 1px solid #cccccc !important;
    box-shadow1: 0 0 2px #cccccc !important;
    background1: #6343a4 !important;
    background1: #cfeeff !important;
    background1: #d2f1e5 !important;
    background1: #ffffff !important;
    background1: #3f3957 !important;

}

.v-navigation-drawer__border {
    border: 12px solid !important;
    width: 0px !important;
}

.app-bar {
    background: #f5f8fb !important;
    background: #f1f5fe !important;
    background1: #f5f8fb !important;
    background1: #f2f3f9 !important;
    background: #ffffff !important;
    background1: #f5f6fa !important;
    background1: #f5f8fb !important;
    height: 55px !important;
    border-bottom: 0px solid #ff0000 !important;
    background: #c6e3fd !important;

}

.d-icon {
    float: left !important;
    color: #11a1fd !important;
    background1: #11a1fd;
    margin-left: 40px;
}

.brand {
    background: #ffffff !important;
    padding: 0 !important;
    width: 240px !important;
    display: inline-flex !important;
    margin-left: -16px !important;
    background1: #605bff !important;
    background1: #6343a4 !important;
    height: 63px;
    color: #222222 !important;
    padding-top: 10px !important;
    background: #f1f5fe !important;
    background: #f1f5fe !important;
    color1: #5e79fd !important;
    color: #ffffff !important;
    padding-left: 0 !important;
    font-weight: 600 !important;
    ;

}

.top-title {
    float: left;
}

.main-bg {
    background: #f5f8fb !important;
    background: #f2f3f9 !important;
    background: #ffffff !important;
    background: #f1f1ff !important;
    background: #f9fafc !important;

    background: #f5f6fa !important;
    background: #f5f8fb !important;
    background: #c6e3fd !important;

}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #012a42 !important;
    color: #949393 !important;
    color: #7f84a4 !important;
    color: #bfbfc0 !important;
    color: #aa8aef !important;
    color: #8f9091 !important;
    color: #92a6ba !important
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
    font-size: 14px !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) i {
    color: #949393 !important;
    margin-right: 0px !important;
    color: #7081b9 !important;
    color: #bfbfc0 !important;
    color: #aa8aef !important;
    color: #8f9091 !important;
    margin-top: 0px !important;
    color: #7e8fa9 !important
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
    opacity: 0 !important;
    background: #24aeee !important;
    color: #ffffff !important;
}

.theme--light.v-list-item--active,
.theme--light.v-list-item--active {
    opacity: 1 !important;
    background1: #ededfd !important;
    color1: #e0e4fc !important;
    font-weight: 500;
    border-radius: 0;
    border-right: 4px solid #282586;
    width: 100% !important;
    margin-left: 3px;
    min-width: 230px;
    padding-left: 5px !important;
    color: #4e56a9 !important;
    background1: #e6e9fe !important;
    border-right: 4px solid #5e79fd;
    border-radius1: 0 5px 5px 0;
    color: #0f2d69 !important;
    background1: #e5e5e6 !important;
    color: #ffffff !important;
    background: rgba(0, 0, 0, 0.1)
}

.v-list .v-list-item--active .v-icon {
    color: #4e56a9 !important;
    margin-top: 0 !important;
    color: #5e79fd !important;
}

.v-list-item {
    height: 30px !important;
    min-height: 35px !important;
    ;
}

.v-list-item__icon {
    margin-right: 15px !important;
    height: 0px !important
}

.menu-icon {
    font-size: 20px !important;
    font-weight: 100 !important;
}

#SidebarNavigation {
    margin-top: 50px;
}

.search-control {
    margin-left: 50px;
}

.search-control input {
    border1s: 1px solid #cccccc;
    border-radius: 30px;
    min-width: 100%;
    width: 500px;
    padding: 7px 20px;
    background: #f5f8fb !important;
}

.search-control input:focus {}

.brand,
.nav-drawer {
    background1: #0c2556 !important;
    background1: #e3fbff !important;
    background: #f5f8fb !important;
    border-right1: 1px solid #e6e6e6;
    box-shadow2: 0 0 2px #dddddd !important;
    background: #123858 !important;

}

.brand {
    background: #ffffff !important;
    color: #5e79fd !important;
    color: #051e97 !important;
    background: #062844 !important;
    color: #ffffffcc !important;

}

.snaksuccess {
    background: #ff0000 !important;
}
</style>
