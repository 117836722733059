import Api, { MainAppApi } from './api';

export default {
     getAll(path, data){
          const h = {Authorization: "Bearer " + localStorage.getItem('accesstoken')}
          return Api.get(path + '/' + JSON.stringify(data),{headers:h})
     },
     save(path, data){
          const h = {Authorization: "Bearer " + localStorage.getItem('accesstoken')}
          return Api.post(path,data,{headers:h})
     },
     update(path, data){
          const h = {Authorization: "Bearer " + localStorage.getItem('accesstoken')}
          return Api.put(path+'/'+data._id,data,{headers:h})
     },
     adminLogin(){
          const h = {Authorization: "Bearer " + localStorage.getItem('accesstoken')}
          return Api.post('admin/login',{}, {headers:h})
     },

    lmAPI: {
      lmAllAgents(adminToken) {
        const h = {Authorization: `${adminToken}`}
        return MainAppApi.get('admin/all-agents', {headers:h})
      }
     }
}


