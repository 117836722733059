import Attachment from './Attachment';
export default {
     state:{
          files:[]
     },
     getters:{
          getFiles(state){
               var files = state.files;
               return files
          }
     },
     actions:{
          
          UPLOAD_ATTACHMENT({commit}, data){
               return new Promise ((resolve, reject) => {
                   return Attachment.upload(data).then(response =>{
                         if(response.data.success){
                              console.log("Files uploaded:",response.data);
                              commit('SET_FILE', response.data.items)
                              resolve(response.data);
                         }
                         else{
                              console.log(response.data);
                              reject(response.data)
                         }
                    }).catch((e)=>{
                         console.log(e);
                         reject(e)
                    })
               })               
          },
          
          GET_ATTACHMENT({commit}, id){
               console.log("reached",id, commit);
               return new Promise ((resolve, reject) => {
                     Attachment.get(id).then((response) =>{
                         var info = JSON.parse(response.headers['content-type']);
                         //var mime = info.type;
                         var fl = info.name;
                         const url = window.URL.createObjectURL(new Blob([response.data]));
                         const link = document.createElement('a');
                         link.href = url;
                         link.setAttribute('download', fl); //or any other extension
                         document.body.appendChild(link);
                         link.click();

                         if(response.status===200){
                            //  console.log("response",response);
                              resolve(response.data);
                              setTimeout(()=>{
                                   resolve(response.data);      
                              },1000)
                               
                         }
                         else
                              reject(response.data)
                    }).catch((e)=>{
                         console.log(e);
                         reject(e)
                    })
               })                              
          }
     }
     ,
     mutations:{
          SET_FILE(state, data){
               state.files = data;
          }
     }
}
