import Substitution from './Substitution';

const getDefaultState = () =>{
     return {
          currentDoc:{
               user_from:null,
               user_to:null,
               date_from:null,    
               date_to:null,
               createdby:null,
               updatedby:null                              
          },
          onbehalf:[],
          subs:[]
     }
}
const state = getDefaultState();
const actions = {
     CREATE_SUB({commit},data){
          return new Promise ((resolve, reject) => {
              return Substitution.create(data)
                         .then((response) =>{commit('CLEAR_FORM',''); resolve(response)})                 
                         .catch(e => reject(e))
                    })               
     },
     
    SUBS_ALL({commit}){
          return new Promise ((resolve, reject) => {
              return Substitution.all().then(response =>{
                    if(response.data.success){
                         commit('SET_FORMS', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     
     CLEAR_FORM_STATE({commit},data){
          commit('CLEAR_FORM',data);
     }
}

const mutations = {
        SET_FORMS(state, data){
          state.subs = data;
        },
        CLEAR_FORM(state,data){
             Object.assign(state,getDefaultState());     
        }
}

export default {
     state,
     getters:{},
     actions,
     mutations
}

