import Api from '../../backend/api';
//import {AuthHeaders} from '../../backend/headers';
//const headers = AuthHeaders();
//const headers = {
//     Authorization: "Bearer " + localStorage.getItem('accesstoken')
//}
//import headers from '../../backend/headers';

export default {
     authenticate(user){
          return Api.post('users/authenticate',user)
     },
     getOnBehalf(email){
  //        console.log("headers",headers);
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          return Api.get('forms/my/subs/'+email,{headers:auth})
     },
     getTaskCounts(user){
          //console.log("headers:",headers);
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          return Api.get('users/stats/'+JSON.stringify(user),{headers:auth})
     },
     searchUser(term){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
         
          return Api.get('users/'+term,{headers:auth})
     }    
}


