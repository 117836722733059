import Vue from 'vue'
import Router from 'vue-router'
import ProviderList from '@/components/ProviderList';
import Dashboard from '@/components/Dashboard';
import ProductList from '@/components/ProductList';
import UWList from '@/components/UWList';

import PlanList from '@/components/PlanList';
import AccountList from '@/components/AccountList';
import AgentList from '@/components/AgentList';
import Login from '@/components/Login'
import TestAPI from '@/components/TestAPI'



Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path:'/dashboard',
      name:'dashboard',
      alias:'/',
      component: Dashboard,
      children:[
        {
          path: '/providers',
          name: 'providers',
          component: ProviderList,
        },
        {
          path: '/products',
          name: 'products',
          component: ProductList,
        },
        {
          path: '/uw',
          name: 'uw',
          component: UWList,
        },
       
        {
          path: '/plans',
          name: 'plans',
          component: PlanList,
        },
        {
          path: '/accounts',
          name: 'accounts',
          component: AccountList,
        },
        {
          path: '/agents',
          name: 'agents',
          component: AgentList,
        },
        {
          path: '/testapi',
          name: 'testapi',
          component: TestAPI,
        }
      ]   
    },

    
  ]
})