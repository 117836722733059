import Api from './api';
export default {
     get(data){
        const h = {Authorization: "Bearer " + localStorage.getItem('accesstoken')} 
        var dob = new Date(data.dob);
        var dob_month = dob.getMonth();
        
        var dob_year = dob.getFullYear();
        var curr_year = new Date();
        var curr_month = curr_year.getMonth();
        
        curr_year = curr_year.getFullYear();
        var age = curr_year - dob_year;
        
        if(curr_month<dob_month)
        age = age -1
        
          data.provider_id == '' ? delete data.provider_id : '';
          data.product_id == '' ? delete data.product_id : '';
          data.age = age;  
          return Api.post('quote',data,{headers:h})
     }
}