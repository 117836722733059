<template>
<div class="inner-container">
    <div class="widget">
        <v-row>
            <v-col>
                <h3>Test API</h3>

            </v-col>
            <v-col cols="2">
                <v-btn class="iv-btn" @click="fillDefault()">
                    <v-icon class="btn-icon" style="font-size:40px !important">mdi-basket-fill</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-container>
            <v-form v-model="valid" ref="form">

                <v-row>
                    <v-col cols="3">
                        <v-select hide-details v-model="mystore.state" filled dense :items="state_items" label="Select state" item-text="name" item-value="val"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select required :rules="[rules.required]" hide-details v-model="mystore.product_category" filled dense :items="product_categories" label="Product Categories"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select hide-details v-model="mystore.provider_id" filled dense :items="provider_items" label="Select provider" item-text="title" item-value="_id" @change="loadProducts()"></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-select hide-details v-model="mystore.product_id" filled dense :items="product_items" label="Select product" item-text="title" item-value="_id"></v-select>
                    </v-col>

                </v-row>

                <v-row>
                    <v-col>
                        <v-select required :rules="[rules.required]" hide-details v-model="mystore.sex" filled dense :items="[{m:'Male',v:'M'},{m:'Female',v:'F'}]" label="Gender" item-text="m" item-value="v"></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field required :rules="[rules.required]" dense v-model="mystore.dob" label="Date of Birth" filled v-mask="'##/##/####'" placeholder="MM/DD/YYYY"></v-text-field>
                        <span v-if="mystore.dob!=''">{{calculateAge()}} - years <span v-if="month_old > -1"> - {{month_old}} months </span>  old </span>
                    </v-col>
                    <v-col>
                        <v-select hide-details v-model="mystore.tobacco" filled dense :items="[{s:'Yes',v:true},{s:'No',v:false}]" label="Smoker" item-text="s" item-value="v"></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field hide-details dense required :rules="[numberRule]"  v-model="mystore.faceamount" label="Coverage Amount" v-mask="mask" filled></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
            <v-row>
                <v-col cols="2">
                    <v-btn class="btn-load1" @click="findQuote()">
                        <v-icon class="btn-icon" style="font-size:25px !important">mdi-refresh</v-icon>
                        LOAD
                    </v-btn>
                </v-col>
                <v-col>
                    <v-alert border="left" class="text-center" text type="info" icon="mdi-information" v-if="message!=''">
                        {{message}}
                    </v-alert>
                </v-col>
            </v-row>
            <v-row v-if="quoteResults.length>0" class="justify-center mt-5 no-gutters">
                <v-col cols="3" v-for="x in quoteResults">

                    <v-card class="text-center justify-center mycard ma-2">
          <v-icon v-if="x.noofrules" large color="red" v-bind="attrs" v-on="on" right class="float-right mr-2 mt-2" >mdi-alert-circle</v-icon>

                        <v-card-text class="text-center">
                            <h1 style="font-size:16px;font-weight:400;padding:0;margin:0">{{x.provider.title}}</h1>
                        </v-card-text>

                        <h1 style="font-size:20px;font-weight:400;color:#ff9900" >{{x.title}}</h1>

                        <v-card-title class="justify-center" v-if="!x.noofrules">

                            <h3 class="display-1" style="color:#009900" >${{x.monthly}}</h3> <br />

                        </v-card-title>
                                                <v-card-text v-if="!x.noofrules" class="text-center">
                                                    Per Month
                                                </v-card-text>
                        <v-card-text class="text-left">
                             <span v-for="(r,indexz) in x.rules" style="color:red">
          <template v-if="!r.pass && r.ruleMessage.length > 2">{{r.ruleMessage}}
              <div v-if="x.rules.length - 1 !=indexz" style="border:1px dotted #dddddd;height:1px;width:100%;margin: 5px 0px 5px 0px">
              </div>
              </template>
      </span>

                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>

        </v-container>
    </div>
</div>
</template>

<script>
import quoteAPI from '../backend/quote';
import genericAPI from '../backend/generic';
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import moment from 'moment';

const config = require('../../config');
const currencyMask = createNumberMask({
    prefix: "$",
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
});

export default {
    data() {
        return {
            e1: 1,
            message: '',
            mystore: {
                state: '',
                product_category: '',
                gender: '',
                dob: '',
                tobacco: false,
                faceamount: 0,
                provider_id:'',
                product_id:'',
            },
            product_categories: [
                "Term-5",
                "Term-10",
                "Term-15",
                "Term-20",
                "Term-25",
                "Term-30",
                "Term-5-ROP",
                "Term-10-ROP",
                "Term-15-ROP",
                "Term-20-ROP",
                "Term-25-ROP",
                "Term-30-ROP",
                "WLFE"
            ],
            state_items: [],
            productList: [],
            selectedProduct: null,
            quoteResults: [],
            state_items: config.states,
            provider_items: [],
            product_items: [],
            provider: '',
            product: '',
            mask: currencyMask,
            isValidForm: true,
            valid: false,
            vx: '',
            rules: {
                required: value => !!value || 'Required.',
            },
            numberRule: v => {
                if(v=='$0' || v=='' || v==null || v=='$'){
                    return 'Should be greater than zero'
                }else
                return true
            },
            month_old:''

        }
    },
    methods: {
        findQuote() {
            if (!this.$refs.form.validate()) return;

            this.firstClick = true;
            quoteAPI.get(this.mystore).then((x) => {
                this.selectedProduct = null;
                this.productList = x.data.msg;
                console.log(x);
                this.quoteResults = x.data.msg;

                this.message = '';
                if (this.quoteResults.length <= 0)
                    this.message = "No rates/products available for this criteria!"

            })
        },
        fillDefault() {
            this.mystore = {
                state: '',
                product_category: 'Term-15',
                sex: 'M',
                dob: '12/12/1980',
                tobacco: false,
                faceamount: 50000,
                product_id:'',
                provider_id:''
            }
            this.quoteResults = [];
        },
        loadProviders() {
            genericAPI.getAll('/entity/providers/status',{status:1}).then((x) => {
                this.provider_items = x.data.msg;
                console.log("provider_items:", this.provider_items);
            }).catch((e) => {
                console.log("getAll():", e);
            });
        },

        loadProducts() {

            genericAPI.getAll('/entity/products/'+ this.mystore.provider_id + '/provider._id',{status:0}).then((x) => {
                this.product_items = x.data.msg;
                console.log("provider_items:", this.product_items);
            }).catch((e) => {
                console.log("getAll():", e);
            });
        },
        calculateAge(){
            var dob = new Date(this.mystore.dob);
            var dob_month = dob.getMonth() + 1;
            var dob_year = dob.getFullYear();
            var curr_year = new Date();
            var curr_month = curr_year.getMonth() +1;

            curr_year = curr_year.getFullYear();
            var age = curr_year - dob_year;

            if(curr_month<dob_month){
                age = age -1;
                this.month_old = 12 - dob_month +  curr_month
            }else
            this.month_old = curr_month - dob_month;

            return age

        }
    },
    mounted() {
        this.loadProviders();
    }
}
</script>

<style scoped>
.ow {
    background: #ffffff !important;
}

.btn-load {
    background: #ff9900 !important;
    padding: 25px !important;
    color: #ffffff !important;
    box-shadow: none !important;
}

.mycard {
    box-shadow1: none !important;
    border1: 2px solid #ba8afa
}
</style>
