<template>
<div class="inner-container">
    <div class="widget">
        <v-snackbar v-model="sbar.show" :timeout="sbar.timeout" absolute centered top :color="sbar.stl" elevation="24">
            {{sbar.message}}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="sbar.show=false">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-row>
            <v-col>
                <h3>Product List</h3><br />
                <v-text-field class="search-box" outlined hide-details dense v-model="search" append-icon="mdi-magnify" label="Search" single-line></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-btn @click="entryMode = 0;isVisible = true;itemEntry={...itemBlankEntry}" class="iv-btn">
                    <v-icon class="btn-icon">mdi-plus-circle</v-icon>
                </v-btn>
                <br /><br />

                <v-btn-toggle dense borderless class="float-right" color="#0c2556" v-model="btngroup">
                    <v-btn @click="loadForm(1)">
                        <v-icon left>
                            mdi-lightbulb-on
                        </v-icon>
                        <span class="hidden-sm-and-down"> Active</span>
                    </v-btn>

                    <v-btn @click="loadForm(0)">

                        <v-icon left>
                            mdi-lightbulb-off
                        </v-icon>
                        <span class="hidden-sm-and-down">Inactive</span>

                    </v-btn>
                </v-btn-toggle>
            </v-col>

        </v-row>

        <v-data-table :headers="headers" :items="items" class="list" :search="search">
            <template v-slot:item.title="{ item }">
                <span class="list-title">{{item.title}}</span>
            </template>
            <template v-slot:item.status="{ item }">
                <span v-if="item.status" style="color: #009900; font-weight: 600">ACTIVE</span>
                <span v-if="!item.status" style="color: #999999; font-weight: 600">INACTIVE</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon title='Edit' color="blue" style="font-size: 20px" @click="
              itemEntry = item;
              entryMode = 1;
              isVisible = true;
            ">
                    mdi-square-edit-outline
                </v-icon>
                <v-icon alt="Currency" title="Age Rates" :color="item.rates.length > 0 ? 'orange' : ''" style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 1;
              isRateVisible = true;
            ">
                    mdi-currency-usd
                </v-icon>

                <v-icon title='Restriction Rules' :color="item.rules.length > 0 ? 'green' : '' " style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 1;
              tempRules = [...itemEntry.rules];
              isRuleVisible = true;
            ">
                    mdi-cube-scan
                </v-icon>

                <v-icon title='Height and Weight Chart' :color="item.hasOwnProperty('hw') && item.hw.length > 0 ? 'green' : '' " style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 1;
              tempHW = item.hasOwnProperty('hw') ? [...itemEntry.hw] : []
              isHWVisible = true;
            ">
                    mdi-tape-measure
                </v-icon>

            <v-icon title='Underwritting Conditions' :color="item.hasOwnProperty('uw') && item.uw.length > 0 ? 'green' : '' " style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 1;
              tempUW = item.hasOwnProperty('uw') ? [...itemEntry.uw] : []
              isUWVisible = true;
              filterUW()
            ">
                    mdi-alpha-u-circle-outline
                </v-icon>


                <v-icon title='Clone this product' color="silver" style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 0;
              isVisible = true;
            ">
                    mdi-content-duplicate
                </v-icon>

            </template>
        </v-data-table>
    </div>

    <v-row justify="center">
        <v-dialog v-model="isVisible" persistent max-width="500" overlay-opacity="0.8">
            <v-form v-model="isValidForm">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ FormTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-select dense v-model="itemEntry.provider" :items="providers" label="Provider" item-text="title" item-value="_id" return-object>
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-select dense v-model="itemEntry.product_type" :items="product_types" label="Product Type">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-select dense v-model="itemEntry.product_category" :items="product_categories" label="Product Category">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-select dense v-model="itemEntry.premium_type" :items="premium_items" label="Premium Type" item-text="title" item-value="val">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field v-model="itemEntry.title" label="Title" :rules="[formRules.required]" dense></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field v-model="itemEntry.policyfee" label="Policyfee" :rules="[formRules.required]" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field v-model="itemEntry.rateform" label="Rate calculation formula" :rules="[formRules.required]" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-checkbox v-model="itemEntry.status" label="Status"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" small @click="isVisible = false">Cancel</v-btn>
                        <v-btn v-if="entryMode == 0" color="success" small @click="
                  isVisible = false;
                  saveForm();
                " :disabled="!isValidForm">Save</v-btn>
                        <v-btn v-if="entryMode == 1" color="success" small @click="
                  isVisible = false;
                  updateForm();
                " :disabled="!isValidForm">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>

    <v-row justify="center">
        <v-dialog v-model="isRateVisible" persistent max-width="500" overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <v-row no-gutters>
                        <v-col>
                            <span class="headline ">Manage Rates</span>
                        </v-col>
                        <v-col align="right" class="mr-2">
                            <v-icon large color="blue" @click="itemEntry.rates.push({age:0,ns:0,sm:0})">mdi-plus</v-icon>
                        </v-col>
                    </v-row>

                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-data-table dense :headers="rate_headers" :items="itemEntry.rates" class="list-rate">
                            <template slot="item" scope="props">
                                <tr>
                                    <td>
                                        <v-text-field v-model="props.item.age" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="props.item.ns" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="props.item.sm" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="props.item.sex" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon title="Delete" color="red" style="font-size: 20px; margin-left: 10px" @click="itemEntry.rates.splice(props.index,1)">mdi-trash-can-outline</v-icon>
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error1" small @click="isRateVisible = false">Cancel</v-btn>
                    <v-btn text v-if="entryMode == 0" color="success" small @click="isRateVisible = false;saveForm();">Save</v-btn>
                    <v-btn text v-if="entryMode == 1" color="green" small @click="isRateVisible = false;updateForm();">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <v-row justify="center">
        <v-form v-model="isRuleValidForm">

            <v-dialog v-model="isRuleVisible" persistent max-width="1000" overlay-opacity="0.8">
                <v-card>
                    <v-card-title>
                        <v-row no-gutters>
                            <v-col>
                                <span class="headline ">Manage Rules</span>
                            </v-col>
                            <v-col align="right" class="mr-2">
                                <v-icon large color="blue" @click="tempRules.push({type:'AND',title:'',ruleFormula:'',ruleMessage:''})">mdi-plus</v-icon>
                            </v-col>
                        </v-row>

                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-data-table dense :headers="rule_headers" :items="tempRules" class="list-rate">

                                <template slot="item" scope="props">
                                    <tr>
                                         <td>
                                            <v-select v-model="props.item.type" dense hide-details :items="['AND','OR']"></v-select>
                                        </td>

                                        <td>
                                            <v-text-field v-model="props.item.title" dense hide-details></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field v-model="props.item.ruleFormula" dense hide-details></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field v-model="props.item.ruleMessage" dense hide-details></v-text-field>
                                        </td>

                                        <td>
                                            <v-icon title="Delete" color="red" style="font-size: 20px; margin-left: 10px" @click="tempRules.splice(props.index,1)">mdi-trash-can-outline</v-icon>
                                        </td>
                                    </tr>
                                </template>

                            </v-data-table>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="error1" small @click="isRuleVisible = false;tempRules=[]">Cancel</v-btn>
                        <v-btn text v-if="entryMode == 0" color="success" small @click="itemEntry.rules=tempRules;isRuleVisible = false;saveForm();" :disabled="!isRuleValidForm">Save</v-btn>
                        <v-btn text v-if="entryMode == 1" color="green" small @click="itemEntry.rules=tempRules;isRuleVisible = false;updateForm();" :disabled="!isRuleValidForm">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </v-row>

    <v-row justify="center">
        <v-dialog v-model="isHWVisible" persistent max-width="620" overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <v-row no-gutters>
                        <v-col>
                            <span class="headline ">Manage Height & Weight</span>
                        </v-col>
                        <v-col align="right" class="mr-2">
                            <v-icon large color="blue" @click="tempHW.push({hfeet:0,hinch:0,wmin:0,wmax:0})">mdi-plus</v-icon>
                        </v-col>
                    </v-row>

                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-data-table dense :headers="hw_headers" :items="tempHW" class="list-rate">
                            <template slot="item" scope="props">
                                <tr>
                                    <td>
                                        <v-text-field v-model="props.item.hfeet" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="props.item.hinch" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="props.item.wmin" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field v-model="props.item.wmax" dense hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon title="Delete" color="red" style="font-size: 20px; margin-left: 10px" @click="itemEntry.hw.splice(props.index,1)">mdi-trash-can-outline</v-icon>
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error1" small @click="isHWVisible = false;tempHW=[]">Cancel</v-btn>
                    <v-btn text v-if="entryMode == 0" color="success" small @click="itemEntry.hw=tempHW;isHWVisible = false;saveForm();">Save</v-btn>
                    <v-btn text v-if="entryMode == 1" color="green" small @click="itemEntry.hw=tempHW;isHWVisible = false;updateForm();">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <v-row justify="center">
        <v-dialog v-model="isUWVisible" persistent max-width="1200px" overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <v-row no-gutters>
                        <v-col>
                            <span class="headline ">Manage Underwritting</span>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-title>
                           <v-row >
                        <v-col cols="3">
                                <v-select hide-details filled dense v-model="qcat" :items="uw_cats" label="Type" item-text="title" item-value="val" @change="filterUW()">
                                </v-select>
                        </v-col>
                        <v-col>
                                <v-select filled hide-details dense v-model="selUW" :items="fuw" label="Question/Criteria" item-text="title" item-value="_id" return-object>
                                </v-select>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-subtitle>

                     <v-row class="mt-4">
                       <v-col  cols="3">
                           <v-btn depressed outlined block @click="tempUW.push({_id:selUW._id,qcat:selUW.qcat,ques:selUW.title,qtype:selUW.qtype,uwformula:'',uwmsg:''})">
                                 <v-icon color="blue">mdi-plus</v-icon>
                                 ADD
                           </v-btn>
                        </v-col>
                      </v-row>
                </v-card-subtitle>

                <v-card-text>
                    <v-container>
                        <v-data-table dense :headers="uw_headers" :items="tempUW" class="list-rate">
                            <template slot="item" scope="props">
                                <tr>
                                    <td>
                                        {{uw_cats[props.item.qcat - 1].title}}
                                    </td>
                                    <td>
                                        {{props.item.ques}}
                                    </td>
                                    <td>
                                        {{
                                                props.item.qtype == 1 ? 'Timeline' : props.item.qtype == 2 ? 'Yes/No' : ''
                                         }}
                                    </td>
                                    <td>
                                        <v-text-field  v-model="props.item.uwformula" hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field  v-model="props.item.uwmsg" hide-details></v-text-field>
                                    </td>
                                    <td>
                                        <v-icon title="Delete" color="red" style="font-size: 20px; margin-left: 10px" @click="tempUW.splice(props.index,1)">mdi-trash-can-outline</v-icon>
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error1" small @click="isUWVisible = false;tempUW=[]">Cancel</v-btn>
                    <v-btn text v-if="entryMode == 0" color="success" small @click="itemEntry.uw=tempUW;isUWVisible = false;saveForm();">Save</v-btn>
                    <v-btn text v-if="entryMode == 1" color="green" small @click="itemEntry.uw=tempUW;isUWVisible = false;updateForm();">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>


</div>
</template>

<script>
import genericAPI from '../backend/generic';

export default {
    data() {
        return {
            btngroup: 0,
            search: '',
            apiPath: 'entity/products',
            apiPathUW: 'entity/uw',
            currentTab: 1,
            headers: [{
                    text: "Product Title",
                    value: "title",
                    align: "start",
                },
                {
                    text: "Category",
                    value: "product_category",
                    align: "start",
                },
                {
                    text: "Type",
                    value: "product_type",
                    align: "start",
                },
                {
                    text: "Provider",
                    value: "provider.title",
                    align: "start",
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                },
                {
                    text: "",
                    value: "actions",
                    align: "end",
                },
            ],
            items: [],
            search: "",
            isVisible: false,
            isValidForm: false,
            isRuleValidForm: false,
            itemEntry: {
                title: "",
                product_type: "",
                product_category: "",
                premium_type: "NA",
                policyfee: "",
                rates: [],
                nearest_age: 1,
                provider: null,
                rateform: "",
                rules: [],
                hw:[],
                uw:[],
                status: 0,
            },
            itemBlankEntry: {
                title: "",
                product_type: "",
                product_category: "",
                premium_type: "NA",
                policyfee: "",
                rates: [],
                nearest_age: 1,
                provider: null,
                rateform: "",
                rules: [],
                hw:[],
                uw:[],
                status: 0,
            },
            blankRule: {
                title: '',
                ruleFormula: '',
                ruleMessage: ''
            },
            product_categories: [
                "Term-5",
                "Term-10",
                "Term-15",
                "Term-20",
                "Term-25",
                "Term-30",
                "Term-5-ROP",
                "Term-10-ROP",
                "Term-15-ROP",
                "Term-20-ROP",
                "Term-25-ROP",
                "Term-30-ROP",
                "WLFE"
            ],
            premium_items: [{
                    title: "Not Applicable",
                    val: "NA"
                },
                {
                    title: "Standard",
                    val: "SPT"
                },
                {
                    title: "Preferred",
                    val: "PPT"
                },
                {
                    title: "Graded - Modified",
                    val: "GMT"
                },
                {
                    title: "Guaranteed",
                    val: "GPT"
                }
            ],
            product_types: ["Term", "Whole Life/Final Expense"],
            providers: [],
            entryMode: 0,

            formRules: {
                required: (value) => !!value || "Required.",
            },
            isFeatureVisible: false,
            isRateValidForm: false,
            isRateVisible: false,
            isRateValidForm: false,
            rate_items: [],
            rate_headers: [{
                    text: "Age",
                    value: "age",
                },
                {
                    text: "NS Rate",
                    value: "ns",
                },
                {
                    text: "SM Rate",
                    value: "sm",
                },
                {
                    text: "Sex",
                    value: "sex",
                },
            ],
            hw_items: [],
            hw_headers: [{
                    text: "Feet",
                    value: "hfeet",
                    width:50
                },
                {
                    text: "Inches",
                    value: "hinch",
                    width:50
                },
                {
                    text: "Min Weight",
                    value: "wmin",
                },
                {
                    text: "Max Weight",
                    value: "hmax",
                },
            ],
            tempRules: [],
            tempHW:[],
            uw_headers: [{
                    text: "Type",
                    value: "qcat",
                    width:50
                },
                {
                    text: "Criteria",
                    value: "ques",
                },
                {
                    text: "Answer Type",
                    value: "qtype",
                     width:50

                },
                {
                    text: "Formula",
                    value: "uwformula",
                },
                {
                    text: "Message",
                    value: "uwmsg",
                },
            ],
            tempUW:[],
            uw_items:[],
            fuw:[],
            qcat:1,
            uw_cats:[
                {title:"Medical",val:1},
                {title:"Personal",val:2},
              //  {title:"Lifestyle",val:3},
                {title:"Perscription",val:4},
                {title:"Occupation",val:5},
                {title:"Payment",val:6}
            ],
            selUW:'',
            rule_headers: [
                {
                    text: "Rule Type",
                    value: "type",
                    width: 100
                },
                {
                    text: "Rule Title",
                    value: "title",
                    width: 200
                }, {
                    text: "Rule Formula",
                    value: "ruleFormula",
                },
                {
                    text: "Rule Message",
                    value: "ruleMessage",
                },
                {
                    text: "",
                    value: "action",
                    width: 50
                }
            ],
            rules: [],
            isRuleVisible: false,
            isHWVisible: false,
            isUWVisible: false,

            sbar: {
                show: false,
                timeout: 1500,
                message: '',
                stl: 'success'
            }

        };
    },
    computed: {
        FormTitle() {
            return this.entryMode === 0 ? "New Product" : "Edit Product";
        },
    },
    methods: {
        saveForm() {
            genericAPI.save(this.apiPath, this.itemEntry).then((x) => {
                console.log("x", x);
                this.isVisible = false;
                this.tempRules = [];
                this.showSnakbar(true, 'Updated successfully.')
                this.loadForm(1);

            }).catch((e) => {
                this.showSnakbar(false, e)
                console.log("save():", e);
            });
        },
        updateForm() {
            genericAPI.update(this.apiPath, this.itemEntry).then((x) => {
                console.log("x", x);
                this.isVisible = false;
                this.tempRules = [];
                this.showSnakbar(true, 'Updated successfully.')
                this.loadForm(1);
            }).catch((e) => {
                this.showSnakbar(false, e)
                console.log("update():", e);
            });
        },
        loadForm(status) {
            const criteria = {
                status: status
            }
            genericAPI.getAll(this.apiPath + '/status', criteria).then((x) => {
                this.items = x.data.msg;
                console.log("items", this.items);
                this.loadUWItems(1);
            }).catch((e) => {
                console.log("getAll():", e);
                this.showSnakbar(false, e)
            });
        },
        loadUWItems(status) {
            const criteria = {
                status: status
            }
            genericAPI.getAll(this.apiPathUW + '/status', criteria).then((x) => {
                this.uw_items = x.data.msg;
                console.log("uw_items", this.uw_items);
                return this.uw_items
            }).then((ui)=>{
                this.filterUW(ui);
            })
            .catch((e) => {
                console.log("getAll():", e);
                this.showSnakbar(false, e)
            });
        },
        showSnakbar(success, m) {
            var msg = m;

            if (success == false && m.message.indexOf('status code 401') > 0) {
                msg = '401 - Unauthorized / Expired session / Invalid Token. Please try re-login.'
                success = false
            }

            this.sbar = {
                show: true,
                timeout: success == true ? 2000 : -1,
                message: msg,
                stl: success == true ? 'success' : 'red'
            }
        },
        filterUW(){
            this.fuw = this.uw_items.filter((item) => item.qcat == this.qcat && item.ptype == this.itemEntry.product_type);
                        this.uw_items.filter((item) =>{
                            if(item.qcat === this.qcat && item.ptype === this.itemEntry.product_type)
                            console.log(item.qcat, this.qcat, item.ptype, this.itemEntry.product_type);
                        });

            //this.fuw = this.uw_items.filter((item) => {});

        }
    },

    mounted() {
        genericAPI.getAll('entity/providers/status', {
            status: 1
        }).then((x) => {
            this.providers = x.data.msg;
            console.log("providers:", this.providers);
        }).catch((e) => {
            console.log("getAll('entity/providers'):", e);
        });
        this.loadForm(1);

    }
};
</script>

<style scoped>
.list {
    margin-top: 10px !important;
}

.search-box {
    max-width: 400px !important;
}
</style>
