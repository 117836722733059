import Login from './Login';
import moment  from 'moment';

const getDefaultState = () => {
     return{
          user: {
               mail: '',
               displayName:'Guest',
               title: '',
               department:'',
               employeeID:''
           },
           onbehalf:[],
           accesstoken: localStorage.getItem('accesstoken') || ''   
     }         
} 

const state = getDefaultState();

const actions = {
     LOGIN_USER({commit}, user){
          return new Promise ((resolve, reject) => {
              return Login.authenticate(user).then(async (response) =>{
               if(response.data.success){
                         console.log("Authenticated:",response.data);
                         commit('SET_LOGIN_USER', response.data.user);
                         commit('SET_ACCESSTOKEN', response.data.accesstoken);

                         //var expiry = moment(new Date(),'MM/DD/YYYY').add(1, 'days').toDate();
                         var expiry = moment(new Date(),'MM/DD/YYYY HH:mm:ss').add(12, 'hours');

                         expiry = moment(expiry.toDate()).format('MM/DD/YYYY HH:mm:ss');
                         await localStorage.setItem('user',JSON.stringify(response.data.user));
                         await localStorage.setItem('accesstoken',response.data.accesstoken);
                         await localStorage.setItem('session_expiry',expiry)

                         /*
                         await Login.getOnBehalf(response.data.user.mail).then(async (r)=>{
                              await localStorage.setItem('onbehalf',JSON.stringify(r.data.items));
                              return commit('SET_ON_BEHALF', r.data.items);
                         })*/
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               })
               .catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     GET_TASK_COUNTS({commit}, user){
          return Login.getTaskCounts(user)
     },
     ON_BEHALF({commit},mail){
          return new Promise ((resolve, reject) => {
              return Login.getOnBehalf(mail).then(response =>{
                    if(response.data.success){
                         console.log("ON_BEHALF:",response.data.items);
                         localStorage.setItem('onbehalf',JSON.stringify(response.data.items))
                         commit('SET_ON_BEHALF', response.data.items)
                         resolve(response.data);
                    }
                    else
                         reject(response.data)
               }).catch((e)=>{
                    console.log(e);
                    reject(e)
               })
          })               
     },
     SEARCH_USER({commit},term){
          return new Promise ((resolve, reject) => {
               return Login.searchUser(term)
                         .then(res => resolve(res))
                         .catch(err => reject(err))
           })
     },
     CLEAR_LOGIN_DATA({commit}){
          commit('CLEAR_LOGIN')
     }
}

const mutations = {
     SET_LOGIN_USER(state, user){
          state.user = user;
     },
     SET_ACCESSTOKEN(state, token){
          console.log("token set");
          state.accesstoken = token;
     },
     SET_ON_BEHALF(state, data){
          state.onbehalf = data;
     },
     CLEAR_LOGIN(state){
          Object.assign(state,getDefaultState());                         
     }
}
export default {
     state,
     actions,
     getters:{},
     mutations     
}

