import Api from '../../backend/api';
//import {AuthHeaders} from '../../backend/headers';
//const headers = AuthHeaders();
//import headers from '../../backend/headers';

export default {
     all(){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          return Api.get('subs',{headers:auth})
     },
     create(data){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          return Api.post('subs',data,{headers:auth})
     },
     get(id){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          return Api.get('subs/'+id,{headers:auth})
     },

     update(data){
          const auth = {
               authorization: "Bearer " + localStorage.getItem('accesstoken')
          }
          
          return Api.put('fosub/'+data._id,data,{headers:auth})
     }    
}


