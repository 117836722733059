<template>
<div class="inner-container">
    <div class="widget">
        <v-snackbar v-model="sbar.show" :timeout="sbar.timeout" absolute centered top :color="sbar.stl" elevation="24">
            {{sbar.message}}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="sbar.show=false">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <v-row>
            <v-col>
                <h3>Plan List</h3><br />
                <v-text-field class="search-box" outlined hide-details dense v-model="search" append-icon="mdi-magnify" label="Search" single-line></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-btn @click="entryMode = 0;isVisible = true;itemEntry={...itemBlankEntry}" class="iv-btn">
                    <v-icon class="btn-icon">mdi-plus-circle</v-icon>
                </v-btn>

                <br /><br />

                <v-btn-toggle dense borderless class="float-right" color="#0c2556" v-model="btngroup">
                    <v-btn @click="loadForm(1)">
                        <v-icon left>
                            mdi-lightbulb-on
                        </v-icon>
                        <span class="hidden-sm-and-down"> Active</span>
                    </v-btn>

                    <v-btn @click="loadForm(0)">

                        <v-icon left>
                            mdi-lightbulb-off
                        </v-icon>
                        <span class="hidden-sm-and-down">inactive</span>

                    </v-btn>
                </v-btn-toggle>
            </v-col>

        </v-row>

        <v-data-table :headers="headers" :items="items" class="list" :search="search">>
            <template v-slot:item.title="{ item }">
                <span class="list-title">{{item.title}}</span>
            </template>
            <template v-slot:item.status="{ item }">
                <span v-if="item.status" style="color: #009900; font-weight: 600">ACTIVE</span>
                <span v-if="!item.status" style="color: #999999; font-weight: 600">INACTIVE</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon title='Edit' color="blue" style="font-size: 20px" @click="
              itemEntry = item;
              entryMode = 1;
              isVisible = true;
            ">
                    mdi-square-edit-outline
                </v-icon>
                <v-icon title='Restriction Rules' color="orange" style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 1;
              isFeatureVisible = true;
            ">
                    mdi-format-list-checkbox
                </v-icon>

                <v-icon title='Clone this product' color="silver" style="font-size: 20px; margin-left: 10px" @click="
              itemEntry = item;
              entryMode = 0;
              isVisible = true;
            ">
                    mdi-content-duplicate
                </v-icon>

            </template>
        </v-data-table>
    </div>

    <v-row justify="center">
        <v-dialog v-model="isVisible" persistent max-width="500" overlay-opacity="0.8">
            <v-form v-model="isValidForm">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ FormTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field v-model="itemEntry.title" label="Title" :rules="[rules.required]" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field v-model="itemEntry.monthly" label="Monthly Price" :rules="[rules.required]" dense></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-checkbox v-model="itemEntry.status" label="Status"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" small @click="isVisible = false">Cancel</v-btn>
                        <v-btn v-if="entryMode == 0" color="success" small @click="isVisible = false;saveForm();" :disabled="!isValidForm">Save</v-btn>
                        <v-btn v-if="entryMode == 1" color="success" small @click="isVisible = false;updateForm();" :disabled="!isValidForm">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>

    <v-row justify="center">
        <v-dialog v-model="isFeatureVisible" persistent max-width="500" overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <v-row no-gutters>
                        <v-col>
                            <span class="headline ">Manage Features</span>
                        </v-col>
                        <v-col align="right" class="mr-2">
                            <v-icon large color="blue" @click="itemEntry.features.push({title:''})">mdi-plus</v-icon>
                        </v-col>
                    </v-row>

                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-data-table dense :headers="feature_headers" :items="itemEntry.features" class="list-rate">
                            <template v-slot:item.title="{ item }">
                                <v-text-field v-model="item.title" dense hide-details></v-text-field>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error1" small @click="isFeatureVisible = false">Cancel</v-btn>
                    <v-btn text v-if="entryMode == 0" color="success" small @click="isFeatureVisible = false;saveForm();">Save</v-btn>
                    <v-btn text v-if="entryMode == 1" color="green" small @click="isFeatureVisible = false;updateForm();">Update</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

</div>
</template>

<script>
import genericAPI from "../backend/generic";

export default {
    data() {
        return {
            btngroup: 0,
            search: '',

            apiPath: 'entity/plans',
            currentTab: 1,
            headers: [{
                    text: "Plan Title",
                    value: "title",
                    align: "start",
                },
                {
                    text: "Monthly Price",
                    value: "monthly",
                    align: "center",
                    width: "100"
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    width: "100px"
                },
                {
                    text: "",
                    value: "actions",
                    align: "end",
                    width: "150px"
                },
            ],
            items: [],
            search: "",
            isVisible: false,
            isValidForm: false,
            itemEntry: {
                title: "",
                monthly: "",
                features: [],
                status: 0,
            },
            itemBlankEntry: {
                title: "",
                monthly: "",
                features: [],
                status: 0,
            },
            entryMode: 0,
            rules: {
                required: (value) => !!value || "Required.",
            },
            formRules: {
                required: (value) => !!value || "Required.",
            },
            isFeatureVisible: false,
            isRateValidForm: false,
            feature_items: [],
            feature_headers: [{
                text: "Feature Title",
                value: "title",
            }, ],
            sbar: {
                show: false,
                timeout: 1500,
                message: '',
                stl: 'success'
            }
        };
    },
    computed: {
        FormTitle() {
            return this.entryMode === 0 ? "New Plan" : "Edit Plan";
        },
    },
    methods: {
        saveForm() {
            genericAPI.save(this.apiPath, this.itemEntry).then((x) => {
                console.log("x", x);
                this.isVisible = false;
                this.showSnakbar(true, 'Updated successfully.')
                this.loadForm(1);

            }).catch((e) => {
                this.showSnakbar(false, e)
                console.log("save():", e);
            });
        },
        updateForm() {
            genericAPI.update(this.apiPath, this.itemEntry).then((x) => {
                console.log("x", x);
                this.isVisible = false;
                this.showSnakbar(true, 'Updated successfully.')
                this.loadForm(1);

            }).catch((e) => {
                this.showSnakbar(false, e)
                console.log("update():", e);
            });
        },
        loadForm2()  {
            genericAPI.getAll(this.apiPath).then((x) => {
                this.items = x.data.msg;
                console.log("items", this.items);
            }).catch((e) => {
                console.log("getAll():", e);
            });
        },
        loadForm(status) {
            const criteria = {
                status: status
            }
            genericAPI.getAll(this.apiPath + '/status', criteria).then((x) => {
                this.items = x.data.msg;
                console.log("items", this.items);
            }).catch((e) => {
                console.log("getAll():", e);
                this.showSnakbar(false, e)
            });
        },

        showSnakbar(success, m) {
            var msg = m;

            if (success == false && m.message.indexOf('status code 401') > 0) {
                msg = '401 - Unauthorized / Expired session / Invalid Token. Please try re-login.'
                success = false
            }

            this.sbar = {
                show: true,
                timeout: success == true ? 2000 : -1,
                message: msg,
                stl: success == true ? 'success' : 'red'
            }
        }
    },

    mounted() {
        this.loadForm();
    },
};
</script>
